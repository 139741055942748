<template>
  <div>
    <div class="banner" @click="tz">
      <img class="one" src="../../assets/img/WAP新闻中心-2_02.jpg" alt="" />
    </div>
    <div class="containers">
      <div class="title">
        <span v-if="id == 2">萊珀妮创造时代｜第三代VI视觉全新升级</span>
        <span v-if="id == 1"
          >萊珀妮11周年庆典暨第三代产品全球发布会完美落幕</span
        >
        <span v-if="id == 4">萊珀妮引领时代｜实力共鉴成就卓越</span>
        <span v-if="id == 3">萊珀妮先于时代｜品质服务砥砺前行</span>
        <span v-if="id == 5">Lady praise萊珀妮赋能未来，追梦同行！</span>
      </div>
      <div class="time">
        <span v-if="id == 3">2020-12-01</span>
        <span v-if="id == 4">2020-11-18</span>
        <span v-if="id == 2">2020-12-14</span>
        <span v-if="id == 1">2020-12-21</span>
        <span v-if="id == 5">2021-03-15</span>
      </div>
    </div>
    <div v-if="id == 4" class="container">
      <div>
        <img class="one" src="../../assets/img/12月1号_01.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_02.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_03.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_04.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_05.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_06.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_07.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_08.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_09.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_10.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_11.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_12.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_13.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_14.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月1号_15.jpg" alt="" />
      </div>
    </div>
    <div v-if="id == 2" class="container">
      <div>
        <img class="one" src="../../assets/img/12月14_01.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_02.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_03.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_04.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_05.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_06.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_07.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_08.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_09.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_10.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_11.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_12.gif" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_13.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月14_14.jpg" alt="" />
      </div>
    </div>
    <div v-if="id == 1" class="container">
      <div>
        <img class="one" src="../../assets/img/12月23号_01.jpg" alt="" />
      </div>
      <div style="width: 100%">
        <video
          style="width: 100%; height: 100%"
          controls=""
          autoplay=""
          name="media"
        >
          <source src="../../assets/img/02-xiugai.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_03.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_04.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_05.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_06.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_07.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_08.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_09.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_10.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_11.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_12.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_13.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_14.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_15.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_16.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_17.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_18.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_19.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_20.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_21.jpg" alt="" />
      </div>
      <div style="width: 100%">
        <video
          style="width: 100%; height: 100%"
          controls=""
          autoplay=""
          name="media"
        >
          <source src="../../assets/img/22-xiugai.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_23.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_24.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/12月23号_25.jpg" alt="" />
      </div>
    </div>
    <div v-if="id == 3" class="container">
      <div>
        <img class="one" src="../../assets/img/11月18_01.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_02.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_03.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_04.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_05.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_06.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_07.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_08.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_09.jpg" alt="" />
      </div>
      <div>
        <img class="one" src="../../assets/img/11月18_10.jpg" alt="" />
      </div>
    </div>
    <div v-if="id == 5" class="container">
      <div v-for="item in newImg5" :key="item">
        <img
          class="one"
          v-lazy="require('../../assets/img/20210315培训会/' + item)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.id = this.$route.query.id;
    for (let i = 1; i < 51; i++) {
      this.newImg5.push("培训会_" + i + ".jpg");
    }
  },
  data() {
    return {
      id: "",
      newImg5: [],
    };
  },
  methods: {
    tz() {
      this.$router.push("news");
    },
  },
};
</script>

<style lang="less" scoped>
.b {
  height: 1224px;
}
.banner {
  height: 170px;
}
.containers {
  padding: 35px 22px 0;
  background: #fff;
  .title {
    font-size: 13px;
    color: #333333;
  }
  .time {
    font-size: 11px;
    padding: 10px 0 24px;
    border-bottom: 0.5px dashed #acacac;
  }
}
.container {
  width: 100%;
  background: #fff;
  justify-content: center;
}
</style>